import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PrimaryCta from "./primary-cta"



const HeadingSection = ({ data }) => (
  <div id="heading-section">
    <div className="col-left">
      <h1 className="headline">
        <div className="intro-heading-amicale-ststan">
          {data.titre}
        </div>
      </h1>
      <p className="sub-headline">
        {data.body}
      </p>
      <div className="cta-container">
        <div className="cta-item primary-cta">
          <PrimaryCta scrollTo="missions" text={data.primaryCta.texte}/>
        </div>
      </div>
    </div>
    <div className="col-right">
      <div className="hero-image">
        <GatsbyImage image={getImage(data.illustration)} alt={data.illustration.description}/>
      </div>
    </div>
  </div>
)

export default ({ data }) => (
  <StaticQuery
    query={graphql`
      {
        allContentfulHeroSection {
          nodes {
            titre
            body
            primaryCta {
              lien
              texte
            }
            illustration{
              description
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 97
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    `}
    render={data => (
      <HeadingSection data={data.allContentfulHeroSection.nodes[0]}/>
    )}
  />
)

HeadingSection.propTypes = {
  data: PropTypes.object.isRequired
}
