import React from "react"
import Layout from "../components/layout"
import Formulas from "../components/conseillers"
import Statistics from "../components/statistics"
import Missions from "../components/missions"
import HeadingSection from "../components/heading-section"

export default function Home(props) {

  const path = props.location ? props.location.pathname : null;
  if (typeof window !== 'undefined') {
    sessionStorage.setItem('logo-header-hidden', false);
  }

  return (
    <Layout path={path}>
      <HeadingSection />
      <Missions />
      <Statistics />
      <Formulas />
    </Layout>
  )
}
